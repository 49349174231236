<template>
  <div>
    <h2 class="text-primary">
      {{ $t('pages.auth.login.title') }} 🔑
    </h2>
    <p class="text-lined">
      {{ $t('pages.auth.login.description') }}
    </p>

    <b-row>
      <b-col
        sm="5"
        class="animate__animated animate__bounceInUp"
      >
        <div
          v-if="loader"
          class="mt-4 mb-4 pt-3 pb-3 d-flex align-items-center justify-content-center flex-fill"
        >
          <b-spinner
            variant="primary"
            type="grow"
            :label="$t('therms.loading')"
          />
        </div>
        <template v-else>
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2 mb-4 animate__animated"
              :class="{'animate__shakeX':error}"
              @submit.prevent="submit"
            >
              <server-display />
              <b-form-group
                :label="$t('therms.email')"
                label-size="lg"
                label-for="login-email"
                class="mb-0"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="emailData"
                    type="email"
                    size="lg"
                    autocapitalize="none"
                    spellcheck="false"
                    autocomplete="off"
                    autocorrect="off"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="john.doe@gmail.com"
                  />
                </validation-provider>
              </b-form-group>

              <b-form-group
                :label="$t('therms.password')"
                label-size="lg"
                label-for="login-password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="passwordData"
                      size="lg"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                  </b-input-group>
                </validation-provider>
              </b-form-group>

              <div class="pt-1" />
              <router-link
                tag="a"
                class="text-primary text-up"
                :to="{name:ROUTE_NAME_FORGOT_PASSWORD}"
              >
                {{ $t('pages.auth.login.form.forgot-password') }}
              </router-link>
              <div class="pb-2 pt-1" />

              <!-- submit buttons -->
              <touch-button
                class="btn-rounded"
                type="submit"
                color="primary"
                :disabled="invalid || error"
                :disable="invalid || error"
              >
                {{ $t('therms.login') }}
              </touch-button>
            </b-form>
          </validation-observer>
        </template>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import TouchButton from '@core/components/touch-button/TouchButton.vue'
import {
  BSpinner,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BForm,
  BRow,
  BCol,
  VBTooltip,
} from 'bootstrap-vue'
import ServerDisplay from '@/views/pages/authentication/components/ServerDisplay.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
// eslint-disable-next-line import/no-cycle
import { NAME_ROOT } from '@/router/routes/general'
import { Preferences } from '@capacitor/preferences'
// eslint-disable-next-line import/named
import { LOCALSTORAGE_LAST_EMAIL_KEY } from '@/store/auth'
// eslint-disable-next-line import/no-cycle
import { NAME_FORGOT_PASSWORD, NAME_SERVER } from '@/router/routes/auth'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    TouchButton,
    BSpinner,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BForm,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    ServerDisplay,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      // UI
      loader: false,
      error: false,

      // data
      emailData: '',
      passwordData: '',

      // validation rules
      required,
      email,
    }
  },
  computed: {
    /**
     * @returns {string}
     * @constructor
     */
    ROUTE_NAME_FORGOT_PASSWORD() {
      return NAME_FORGOT_PASSWORD
    },
  },
  watch: {
    error() {
      setTimeout(() => {
        if (this.error) {
          this.error = false
        }
      }, 1000)
    },
  },
  async mounted() {
    if (!this.$store.getters['server/isDefine']) await this.$router.push({ name: NAME_SERVER })

    this.emailData = (await Preferences.get({
      key: LOCALSTORAGE_LAST_EMAIL_KEY,
    })).value
  },
  methods: {
    /**
     * @returns {Promise<void>}
     */
    async submit() {
      try {
        if (!(await this.$refs.loginForm.validate()) || this.error) {
          return
        }

        this.loader = true

        await this.$store.dispatch('auth/login', {
          email: this.emailData,
          password: this.passwordData,
        })

        await this.$router.replace({
          name: NAME_ROOT,
        })
      } catch (e) {
        this.error = true
      } finally {
        this.loader = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-auth.scss';

.auth-wrapper {
  .auth-bg {
    background-color: #F8F8F8;
  }

  .custom-button {
    border-radius: 0.828rem;
    font-weight: 400;
    font-size: 15px;
  }

  label {
    margin-bottom: 0;
    padding-bottom: 0.5rem;
  }
}
</style>
